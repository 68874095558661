<template>
	<div v-if="stored" @mouseover="hoveringMail = email.id" @mouseleave="hoveringMail = ''" class="dF mailCard relative"
		:class="email.id === current.id ? 'highlight' : email.unread == false ? 'read' : ''">
		<div @click="$emit('showemail', email)" class="f1 details-card">
			<small :class="email.id === current.id ? 'text-primary' : ''">
				{{ email.to && email.to[0] && email.to[0].email }}
			</small>
			<div class="dF jSB">
				<div class="mr-2 mailcard-subject" :title="email.subject" v-if="email.subject && email.subject != ''"
					style="color: #000;">{{ email.subject }}</div>
				<small style="min-width:max-content" class="dF jE aC"><a-icon type="clock-circle" class="mr-2"
						style="font-size:10px;" /> {{ moment(email.date).fromNow() }}</small>
			</div>
		</div>
	</div>
	<div @mouseover="hoveringMail = email.id" @mouseleave="hoveringMail = ''" class="dF mailCard relative"
		:class="email.id === current.id ? 'highlight' : email.unread == false ? 'read' : ''"
		v-else-if="email.messages && email.messages.length">
		<div v-if="hoveringMail == email.id" class="absolute" style="top: 20px; right: 20px; z-index: 1000;">
			<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
				<template slot="title">Delete</template>
				<div style="width:14px; margin-left:10px" @click="deleteMessage(email.id)">
					<i style="color:#FD647C; cursor: pointer;" class="fe fe-trash-2 mr-3" />
				</div>
			</a-tooltip>
		</div>
		<div @click="$emit('showemail', email)" class="f1 details-card">
			<small :class="email.id === current.id ? 'text-primary' : ''">
				{{ sent ? email.messages[0].to : email.messages[0].from }}
			</small>
			<div class="dF jSB">
				<div class="mr-2 mailcard-subject" :title="email.messages[0].subject"
					v-if="email.messages[0].subject && email.messages[0].subject != ''" style="color: #000;">
					{{ email.messages[0].subject }}</div>
				<small style="min-width:max-content" class="dF jE aC"><a-icon type="clock-circle" class="mr-2"
						style="font-size:10px;" /> {{ moment(email.messages[0].date).fromNow() }}</small>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	export default {
		props: ['email', 'current', 'sent', 'stored'],
		data() {
			return {
				hoveringMail: ''
			}
		},
		computed: {
			instance() {
				return this.$store.state.instance
			},
			allContacts() {
				return this.$store.state.contacts.allContacts
			},
		},
		methods: {
			moment,
			deleteMessage(msg) {
				this.$emit('updateLoading', true)
				this.$api.delete(`/contacts/:instance/threads/${msg}`).then(({ data }) => {
					this.$emit('removeThread', data)
					this.$emit('updateLoading', false)
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},
			numberWithCommas(x) {
				return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
			},
		}
	}
</script>

<style lang="scss">
	.mailCard {
		.ant-card-body {
			padding: 25px !important;
			width: 400px;
			height: 150px;
		}
	}

	.mailCard.ant-card.ant-card-bordered {
		border: 1px solid #e8e8e8 !important;
		border-top: none !important;
	}

	.popoverStyle .ant-popover-inner-content {
		padding: 0;
		background-color: white;
		border-radius: 20px;
	}

</style>

<style lang="scss" scoped>
	.details-card {
		padding: 20px;
		border-bottom: 1px solid #EFEFEF;
		border-right: 1px solid #EFEFEF;
		border-left: 1px solid #EFEFEF;
	}

	.mailCard.highlight {
		.mailcard-subject {
			font-weight: bold;
		}
	}

	.snippet-line {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #777E8E;
	}

	.mailCard:not(.highlight):hover {
		background-color: #F4F3F6;
	}

	.highlight {
		background-color: var(--primary-vl);
	}

	.read {
		background-color: #FAF9F9;
	}

	.more-option-icon {
		width: 24px;
		height: 24px;
		border-radius: 24px;
		padding: 4px 0;
		text-align: center;
		background-color: transparent;
	}

	.more-option-icon:hover {
		color: var(--orange) !important;
		cursor: pointer;
		background-color: var(--light-gray);
	}

	.popoverContent {
		height: 35px;
		width: 160px;
		line-height: 35px;
		padding-left: 10px;
	}

	.popoverContent:hover {
		background-color: var(--off-white-light);
		cursor: pointer;
	}
</style>
