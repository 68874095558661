<template>
    <div class="custom-scrollbar" style="width:100%; height:calc(100vh - 265px); overflow-y:scroll">
        <div @click="selectedMessage.index = messageI" ref="mailContent" class="mailContent custom-scrollbar" :style="thread.messages.length > 1 && messageI != 0 ? 'margin-top:20px; cursor:pointer' : ''" :class="thread.messages.length == 1 ? 'single-email' :'multiple-email' " v-for="(message,messageI) in thread.messages" :key="messageI" :value="messageI">
            <div class="hide-scrollbar min-h-full" :id="`message${messageI}`" v-if="messageI == selectedMessage.index">
                <div class="dF jSB">
                    <div class="dF aC f1">
                        <a-avatar shape="circle" :size="40" class="mr-3" style="background-color:#1070CA">{{message.from && message.from != '' ? message.from[0].toUpperCase() : $store.state.user.user && $store.state.user.user.firstName && $store.state.user.user.lastName ? `${$store.state.user.user.firstName[0].toUpperCase()}${$store.state.user.user.lastName[0].toUpperCase()}` : ''}}</a-avatar>
                        <div class="f1">
                            <span style="color:#A0AABE">From</span>&nbsp;
                            <span style="color:var(--orange)">{{message.from && message.from != '' ? message.from : $store.state.user.user && $store.state.user.user.firstName && $store.state.user.user.lastName ? `${$store.state.user.user.firstName} ${$store.state.user.user.lastName}` : ''}}</span>&nbsp;
                            <span style="color:#A0AABE">to</span>&nbsp;
                            <span style="color:var(--orange)">{{message.to}}</span>
                            <div class="dF jSB">
                                <h3 class="mt-2">{{message.subject}}</h3>
                                <small style="min-width:max-content" class="dF jE aC"><a-icon type="clock-circle" class="mr-2" style="font-size:10px;" /> {{moment(message.date).fromNow()}}</small>
                            </div>
                        </div>
                    </div>
                    <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                        <template slot="title">Reply</template>
                            <div style="width:14px; margin-left:10px" @click="reply(messageI)" >
                            <svg style="color:#AFB7C8; cursor:pointer" aria-hidden="true" width="14" height="14" focusable="false" data-prefix="fas" data-icon="reply" class="svg-inline--fa fa-reply fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"></path></svg>
                        </div>
                    </a-tooltip>
                </div>
                <div class="mt-5 min-h-full">
                    <EmailFrame v-if="!loading" :key="message.date" :html="message.html" :attachments="message.attachments" />
                </div>
                <div :id="`email-reply${messageI}`">
                    <Mentions v-if="showReply" :activeButton="true" :showMentions="false" :message="''" :template="false" @close="showReply = false" @previewEmail="(e) => sendEmail(message,e)" :okButton="'SEND'" :cancelButton="'CLOSE'" class="task__comment mt-5" />
                </div>
            </div>
            <template v-else>
                <div class="">
                    <div class="">
                        <div class="mr-3" style="color:#000">
                            <small>{{message.from && message.from != '' ? message.from : $store.state.user.user && $store.state.user.user.firstName && $store.state.user.user.lastName ? `${$store.state.user.user.firstName} ${$store.state.user.user.lastName}` :''}}</small>
                        </div>
                        <div class="">
                            <div style="text-overflow:ellipsis; width:334px; overflow:hidden; white-space:nowrap">{{message.snippet}}</div>
                            <small style="min-width:max-content" class="dF jE aC"><a-icon type="clock-circle" class="mr-2" style="font-size:10px;" /> {{moment(message.date).fromNow()}}</small>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import Mentions from '@/components/common/Mentions'
import EmailFrame from '@/components/common/EmailFrame'
import moment from 'moment'
export default {
    components:{Mentions,EmailFrame},
    props:['email'],
    data(){
        return {
            selectedMessage:{
                thread:{},
                index:0
            },
            loading:false,
            showReply:false,
        }
    },
    watch: {
        email:{
            handler(val) {
                this.selectedMessage = {
                    thread:JSON.parse(JSON.stringify(val)),
                    index:val.messages.length-1
                }
                this.showReply = false
            }
        },
        selectedMessage:{
            handler(val) {
                console.log('selected message watch ran', val)
                this.loading = true
                this.$api.get(`/contacts/:instance/messages/${this.thread.messages[val.index].id}/render`).then(({data}) => {
                    if (data.html) this.email.messages[val.index].html = data.html
                    // this.email.messages[val.index].html = 'hi'
                    this.loading = false
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                this.showReply = false
            },
            deep:true
        }
    },
    computed: {
        thread(){
            let email = this.email
            email.messages = email.messages.map(msg => {
                let snippet = msg.text && msg.text.substring(0,100) + '...'
                if (msg.html) {
                    snippet = msg.html.replace(/<[^>]*>/g, '').substring(0,100) + '...'
                }
                msg.snippet = snippet
                return msg
            })
            return email
        },
        users() {
            return this.$store.state.contacts.users
        },
        userEmails() {
            return this.$store.state.contacts.users.map(x => x.email)
        },
        user() {
            return this.$store.state.user.user
        }
    },
    methods:{
        moment,
        sendEmail(message, e) {
            console.log('htmll', e.html, message)
            let innerText = e.html.replace(/<p><br[\/]?><[\/]?p>/g, '')
            if (!innerText.trim()) return this.$message.error('Email needs a body!')
            else {
                let to = message.to.split("<")
                to = to[to.length-1].substring(0, to[to.length-1].length-1)
                let email = {}
                email.html = e.html
                email.subject = `Re: ${message.subject}`
                email.to = to
                email.media = e.attachments
                // email.id = this.email.id
                email.id = message.id // send using message id and not thread id
                console.log('email', email)
                this.$store.commit('LOAD_PAGE', true)
                this.$api.post(`/contacts/:instance/sendmail`, email).then( ({data}) => {
                    console.log('data', data)
                    this.$store.commit('ADD_INBOX', {email:email, threadId:this.email.id})
                    this.$store.commit('LOAD_PAGE', false)
                    this.showReply = false
                    this.$notification['success']({
                        message: 'Email Sent',
                        description: 'Your email has been sent successfully.',
                        duration: 4
                    });
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },
        reply(index) {
            console.log('index', index)
            this.showReply = true
            if (this.showReply) {
                this.goto(`email-reply${index}`)
            }
        },
        reformatContent(text){
            let t = text.replaceAll("\n", "<br>");
            return t
        },
        goto(refName) {
            console.log('refname',refName)
            setTimeout(() => {
                var obj = document.getElementById(refName)
                if(obj != null) {
					obj.scrollIntoView(false)
				}
            }, 200);
            // console.log('refname', refName)
            // var element = document.getElementsByClassName(refName)
            // var top = element[0].offsetTop
            // var height = element[0].clientHeight
            // var scrollTop = top + 45
            // var container = document.getElementsByClassName('mailContent')[0]
            // if (height == 0) {
            //     console.log('height', height, scrollTop)
            //     container.scrollTo(0, scrollTop)
            // }
            // else {
            //     console.log('height', height, top)
            //     container.scrollTo(0, top)
            // }
        }
    },
    mounted(){
        this.$el.querySelector('.mailContent').scrollTop = 0
        this.selectedMessage = {
            thread:{},
            index:this.email.messages.length - 1
        }
    }
}
</script>

<style lang="scss">


</style>

<style lang="scss" scoped>
    .single-email{
        width:100%;
        height:90%;
        background-color:#FFF;
        border-radius:4px;
        padding:40px;
        overflow-y:scroll
    }
    .multiple-email{
        background-color:#FFF;
        padding:40px;
    }
    .attachments {
        display: flex;
        align-items: center;
        text-align: center;
    }
    .attachments::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #EAEEF3;
        margin-left: 1em;
    }
    .replyButton {
        fill:rgba(160,170,190,0.6);
    }
    .replyButton:hover {
        fill:var(--orange);
        transition:fill .1s ease-in;
    }
    .custom-scrollbar::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: #F2F2F2;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: var(--grey-purple);;
        border-radius: 10px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: var(--orange);
    }
</style>
