<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9;">
        <BHLoading :show="bigScreenLoading" />
        <div class="dF jSB">
            <div class="dF aC mb-3 mt-3 mail-category">
                <h4>{{sidebarSelection}} </h4>
                <a-tooltip title="Get new mail" overlayClassName="change-tooltip-color">
                    <a-icon @click="getMail" class="mail-reload ml-2 cursor-pointer hover:text-primary" style="position: relative;top: -2px;font-size: 14px;" type="reload" />
                </a-tooltip>
            </div>
            <MoreActions v-if="checkedList.length" style="position: absolute; z-index: 1; right: 0; margin-top: 1.33rem; margin-right: 1.33rem;" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
        </div>

        <a-row v-if="emailList.length" type="flex" class="w-full h-full relative">
            <BHLoading :show="loading" />
            <!-- List of All Messages -->
            <div class="hide-scrollbar left-email-bar" style="height: 100%;">

                <!-- <div class="dF" style="padding: 25px; background-color: white; border: 1px solid #e8e8e8;">
                    <a-checkbox @change="onCheckAllChange" :checked="checkedList.length === emailList.length" style="margin-right: 25px;" />
                    <div style="color: #3A3B3F; font-weight: 600;">All</div>
                </div> -->
                <a-card class="mailHolder hide-scrollbar f1" style="overflow: scroll; height: calc(100% - 65px); cursor: pointer;">
                    <!-- <div>{{emailList}}</div> -->
                    <div v-for="(mail) in emailList" :key="mail.id">
                        <mailCard @selected="updateSelected" @showemail="showEmail" :email="mail" :current="selectMail.mail" :check="checkedList" @updateLoading="updateLoading" @removeThread="removeThread" />
                    </div>
                    <template v-if="!bigScreenLoading && !maxLoaded">
                        <Observed @observed="loadMore" v-if="nextPage" :key="moreLoaded">
                            <div class="py-3 dF fC aC jC" style="background:#eee;">
                                <a-icon type="loading" /> Loading
                            </div>
                        </Observed>
                    </template>
                </a-card>
            </div>


            <div style="flex: 1; padding: 30px; border:1px solid #e8e8e8; border-left: none; height: 100%; background-color:#FAF9F9">

                <!-- If No Message Selected -->
                <a-card v-if="!selectMail.show" class="mailContent relative w-full h-full" />

                <!-- If Message Selected -->
                <div v-else class="w-full h-full">
                    <mailContent :email="selectMail.mail" />
                </div>
            </div>
        </a-row>

        <template v-else>
            <EmptyApp v-if="(vendors.gmail && vendors.gmail.connectUrl && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active) || (vendors.outlook && vendors.outlook.connectUrl && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active) || (vendors.office365 && vendors.office365.connectUrl && vendors.office365.hasOwnProperty('active') && vendors.office365.active)" :text="'You do not have any emails'" :img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
            <EmptyApp v-else :text="'You haven\'t connected your email account.  To connect, go to Settings and select Email & Calendar Settings > Integrations.'" :img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
        </template>

    </div>
</template>

<script>
    import {getBase64} from 'bh-mod'
    import AEmpty from 'ant-design-vue/lib/empty'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import mailCard from '@/components/common/mailCard'
    import mailContent from '@/components/common/mailContent'
    import MoreActions from 'bh-mod/components/common/MoreActions'
    import Observed from '@/components/common/Observed'


    export default {
        components:{
            AEmpty,BHLoading,EmptyApp,mailCard,mailContent,MoreActions,Observed
        },
        data() {
            return {
                bigScreenLoading:false,
                // moreLoaded:2,
                maxLoaded:false,
                loading:false,
                email:{
                    to:[],
                    from:{},
                    reply:'',
                },
                selectMail:{
                    mail:{},
                    show:false,
                },
                // emailList:[],
                showReply:{
                    show: false,
                    mail:{},
                },

                checkedList: [],
                bulkActions:[
                    {
                        label:'Move to',
                        value:'move'
                    },
                    {
                        label:'Delete',
                        value:'delete'
                    }
                ],
            }
        },
        watch:{

        },
        computed:{
            vendors(){
                return this.$store.state.contacts.vendors || {}
            },
            moreLoaded(){
                return this.$store.state.contacts.moreLoaded
            },
            lastDate(){
                if (!this.emailList.length) return 0
                let last = this.emailList[0]
                let lastMessage = last.messages[last.messages.length -1]
                return (new Date(lastMessage.date).getTime() / 1000)
            },
            nextPage(){
                console.log('this emaillist', this.emailList)
                if (!this.emailList.length) return 0
                return this.emailList[this.emailList.length - 1].nextPageToken || 0
            },
            openedThreads() {
                return this.$store.state.contacts.openedThreads
            },
            emailList() {
                return this.$store.state.contacts.inbox
            },
            allEmails(){
                let l = []
                for (var i = 0; i < this.emailList.length; i++){
                    l.push(this.emailList[i].id)
                }
                return l
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            tags(){
                return this.$store.state.contacts.tags
            },
            searchOBJ(){
                return this.$store.state.contacts.searchOBJ
            },
            resultLength(){
                return this.$store.state.contacts.resultLength
            },
            showingContactMode(){
                return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) `: `Found ${this.resultLength} Contacts `
            },
            instance(){
                return this.$store.state.instance
            },
        },
        methods:{
            removeThread(data){
                console.log('Removing thread...', data);
                let index = this.emailList.findIndex(x => x.id == data.id)
                console.log('Found index', index)
                if (index != -1){
                    this.emailList.splice(index, 1)
                }
            },
            updateLoading(load){
                this.bigScreenLoading = load;
            },
            getMail(){
                let lastDate = this.lastDate
                this.bigScreenLoading = true
                this.$api.get(`/contacts/:instance/inbox`).then(({data}) => {
                    if(data.hasOwnProperty('success') && !data.success) {
                        this.$notification['error']({
                            message: 'No Email Connection',
                            description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
                            duration: 5
                        });
                    } else {
                        console.log('data coming back testing',data)
                        if (lastDate){
                            this.$store.commit('NEW_INBOX', data)
                        } else {
                            this.$store.commit('STORE_INBOX', data)
                        }
                        // this.emailList = data
                    }
                    this.bigScreenLoading = false
                }).catch(err => {
					this.bigScreenLoading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            loadMore(){
                console.log('load more')
                this.$api.get(`/contacts/:instance/inbox/${this.moreLoaded}`).then(({data}) => {
                    if(data.hasOwnProperty('success') && !data.success) {
                        this.$notification['error']({
                            message: 'No Email Connection',
                            description: 'You have not connected your Gmail account.',
                            duration: 5
                        });
                    } else {
                        console.log('data coming back testing',data)
                        if (data && data.length){
                            this.$store.commit('UPDATE_INBOX', data)
                            this.$store.commit('INCREASE_MORE_LOADED')
                        } else {
                            this.maxLoaded = true
                        }
                    }
                    this.loading = false
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            async bulkActioned({key,keyPath,val}){
                let self = this
                console.log('KEY',key)
                if (key == 'move'){
                    console.log('MOVE ALL SELECTED ARCHIVED OPPORTUNITIES')
                }
                if (key == 'delete') {
                    console.log('DELETE ALL SELECTED ARCHIVED OPPORTUNITIES')
                    // this.$confirm({
                    //     title: "Delete",
                    //     content: h => <div>Do you want to delete these forms/surveys?</div>,
                    //     okText: 'Delete',
                    //     okType: 'danger',
                    //     cancelText: 'Cancel',
                    //     centered: true,
                    //     onOk() {
                    //         for (var i=0; i<self.selectedRowKeys.length; i++) {

                    //         }
                    //         setTimeout(() => {
                    //             self.selectedRowKeys = []
                    //         }, 1000);
                    //     },
                    //     onCancel() {
                    //         console.log('Cancel')
                    //     }
                    // })
                }
            },
            updateSelected(e){
                console.log('UPDATING SELECTED', e.target.value)
                if (this.checkedList.includes(e.target.value)){
                    let id = this.checkedList.findIndex( x => {
                        console.log('X', x)
                        if (x == e.target.value){
                            return x
                        }
                    })
                    console.log('IDDDD', id)
                    this.checkedList.splice(id,1)
                } else {
                    this.checkedList.push(e.target.value)
                }

            },
            onCheckAllChange(e) {
                console.log('e', e)
                if (e.target.checked){
                    console.log('TRUEEEEE')
                    let l = []
                    for (var i in this.allEmails){
                        l.push(this.allEmails[i])
                    }
                    this.checkedList = l
                } else {
                    console.log('FALSEEEE')
                    this.checkedList = []
                }
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
            reformatContent(text){
                let t = text.replaceAll("\n", "<br>");
                return t
            },
            async showEmail(obj){
                console.log('selected emal', obj)
                console.log('opened trheads', this.openedThreads)
                if (obj.messages[0].id != this.selectMail.mail.id) {
                    this.showReply = {
                        show:false,
                        mail:{}
                    }
                } if (!this.openedThreads.map(x => x = x.id).includes(obj.id)) {
                    this.loading = true
                    // this.$api.get(`contacts/:instance/threads/${obj.id}`).then(({data}) => {
                    //     console.log('get thread data', data)
                        // obj.read = true
						if (obj.unread){
							this.$api.put(`/contacts/:instance/threads/${obj.id}/read`).then(({data}) => {
								console.log('Read Thread', data)
								obj.unread = false
								this.selectMail.mail = obj
								this.selectMail.show = true
								this.$store.commit('ADD_THREAD', obj)
								this.loading = false
							}).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						} else {
							this.selectMail.mail = obj
							this.selectMail.show = true
							this.$store.commit('ADD_THREAD', obj)
							this.loading = false
						}
                    // })
                } else {
                    // obj.read = true
					if (obj.unread){
						this.$api.put(`/contacts/:instance/threads/${obj.id}/read`).then(({data}) => {
							console.log('Read Thread', data)
							obj.unread = false
							let findThread = this.openedThreads.find(x => x.id == obj.id)
							this.selectMail.mail = findThread
							this.selectMail.show = true
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						let findThread = this.openedThreads.find(x => x.id == obj.id)
						this.selectMail.mail = findThread
						this.selectMail.show = true
					}
                }


                // if (obj.id != this.selectMail.mail.id){
                //     this.showReply = {
                //         show: false,
                //         mail:{}
                //     }
                // }
                // if (!this.openedThreads.map(x => x = x.id).includes(obj.id)) {
                //     this.loading = true
                //     this.$api.get(`contacts/:instance/threads/${obj.id}`).then(({data}) => {
                //         console.log('get thread data', data)
                //         obj.read = true
                //         this.selectMail.mail = data
                //         this.selectMail.show = true
                //         this.$store.commit('ADD_THREAD', data)
                //         this.loading = false
                //     })
                // } else {
                //     obj.read = true
                //     this.selectMail.mail = this.openedThreads.find(x => x.id == obj.id)
                //     this.selectMail.show = true
                // }


                // this.selectMail.mail = obj
                // this.selectMail.show = true
            },
            openReply(){
                console.log('OPENING REPLY BOX')
                this.showReply.show = true
                if (this.showReply.mail != this.selectMail.mail){
                    this.email.reply = ''
                }
                this.showReply.mail = this.selectMail.mail
            },
            closeReply(){
                console.log('CLOSING REPLY BOX')
                this.showReply.show = false
                this.showReply.mail = {}
                this.email.reply = ''
            }

        },
        mounted(){

        },
        created() {
            console.log('the inbox is runnign')
            this.getMail()

            this.$store.commit('SIDEBAR_SELECTION', {value:'Inbox'})
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .left-email-bar{
        width:280px;
        @media screen and (min-width: 1300px) {
            width:400px;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }
    .mail-reload{
        opacity:1;
    }

</style>

<style lang="scss">
    .mailHolder {
        height: 100%;
        .ant-card-body {
            padding: 0;
        }
    }
    .mailHolder.ant-card.ant-card-bordered {
        border: none;
    }
    .mailContent .ant-card-body{
        padding: 48px;
    }
    .replyBox {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        &:focus{
            border-color: #d9d9d9;
            border-right-width: 0;
            box-shadow: none;
            -webkit-box-shadow: none;
        }
        &:hover{
            border-color: #d9d9d9;
        }
    }
</style>
